<template>
  <div>
    <!-- 系统配置-用户管理 -->
    <container>
      <!-- <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div> -->
      <!-- <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div> -->
      <div
        slot="bottom_box_content"
        style="padding:10px 0px;"
      >
        <component
          ref="bottom"
          :is="curBottomComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

import userManageStoreModule from '@/store/modules/userManage'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('@/components/user/UserPage'),
    Right: () => null,
    Left: () => null
  },
  data () {
    return {
      id: 0
    }
  },
  created () {
    if (!this.$store.hasModule('userManage')) {
      this.$store.registerModule('userManage', userManageStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
    // this.setLeftComponent('Left')
  }
}
</script>
